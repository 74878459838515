.line1 {
    white-space: nowrap;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    color: #D61A5D;
    letter-spacing: 0px;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    opacity: 1;
}

.line2 {
    white-space: normal;
    font-size: 50px;
    font-weight: 400;
    color: rgb(33, 43, 104);
    letter-spacing: -1px;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    opacity: 1;
}

.line3_1 {
    white-space: nowrap;
    font-size: 60px;
    font-weight: 700;
    color: rgb(33, 43, 104);
    letter-spacing: 0px;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.line3_2 {
    white-space: normal;
    font-size: 50px;
    line-height: 45px;
    font-weight: 400;
    color: rgb(33, 43, 104);
    letter-spacing: -1px;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.line4 {
    white-space: nowrap;

    font-weight: 700;
    color: rgb(33, 43, 104);
    letter-spacing: 0px;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.line5 {
    margin-top: 20px;
    max-width: 350px;
    white-space: normal;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    color: rgb(17, 17, 17);
    letter-spacing: 0px;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    padding: 0px;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.line6 {
    font-size: 30px;
    line-height: 33px;
    text-align: left;
    font-weight: 400;
    font-style: normal;
}
.line7 {
    font-size: 30px;
    line-height: 33px;
    text-align: left;
    font-weight: 300;
    font-style: normal;
}
